.customScrollbar {
    &::-webkit-scrollbar {
        width: 12px;
        display: none;
    }

    &::-webkit-scrollbar-track {
        // background: #f1f1f1;
        background: none;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        // background: #bebbbb;
        background: none;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

}

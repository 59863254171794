.search-container {
    position: relative;
    z-index: 9;

    input {
        width: 350px;
        height: 40px;
        padding: 8px;
        font-size: 16px;
        // border-radius: 5px;
        border: 1px solid #ABABAB;
        border-radius: 4px;

        @media screen and (max-width: 767px) {
            width: 95%;
            height: 26px;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        position: absolute;
        width: 100%;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        border: 1px solid #ccc;
        border-top: none;
        border-radius: 0 0 4px 4px;
        z-index: 1;
        margin-top: 5px;

        @media screen and (max-width: 767px) {
            font-size: 12px;
        }

        li {
            padding: 8px;
            cursor: pointer;

            &:hover {
                background-color: #f4f4f4;
            }
        }
    }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
p,
h1,
button,
input,
textarea,
.ant-table,
.ant-table-thead>tr>th,
.ant-table-tbody>tr>td,
.ant-col {
  font-family: 'Poppins', sans-serif !important;
}

@media (min-width: 1200px) {

  html,
  body {
    overflow: hidden;
  }

}

body {
  position: relative
}

.ant-picker .ant-picker-suffix {
  color: #008080;
}

.ant-timeline .ant-timeline-item-head-blue {
  color: #008080;
  border-color: #008080;
}

.ant-tabs-ink-bar {
  background-color: #008080 !important;
  /* Change this to your desired color */
}

.ant-tabs-nav .ant-tabs-nav-wrap {
  background: white;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #008080;
}

/* radio button */
.ant-radio-button-wrapper-checked {
  background-color: #E2F6F6 !important;
  color: #008080 !important;
  border-color: #008080 !important;
}

.ant-radio-button-wrapper-checked::before {
  background-color: #008080 !important;
}

.ant-form-item-required {
  font-size: 16px !important;

  @media screen and (max-width: 767px) {
    font-size: 16px !important;
  }
}

label {
  font-size: 16px !important;
  font-family: poppins !important;

  @media screen and (max-width: 767px) {
    font-size: 16px !important;
  }
}


@media screen and (max-width: 767px) {
  .Toastify__toast-container--bottom-left {
    bottom: 80px !important;
  }
}


@media screen and (max-width: 767px) {
  .Toastify__toast-container {
    width: 90vw !important;
    padding: 0 0 0 40px !important;
  }
}

@media screen and (max-width: 767px) {
  .ant-select-selection-item {
    height: 40px !important;
    font-size: 18px;
    margin-top: 8px;
  }

  .ant-select-selector {
    height: 40px !important;
    /* border-color: red !important; */
  }

}
// mobile view
@media screen and (max-width: 767px) {

    .employeeAttendancePolicyManager {
        // margin-top: 20px;

        .headingContainer {
            padding: 0px 16px;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin-bottom: 10px;

            .heading {
                font-size: 24px;
                font-weight: 500;
                color: #161616;
                margin: 0px;
            }
        }

        .cardContainer {
            padding: 0px 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .label {
                font-size: 16px;
                font-weight: 500;
                color: #4A4A4A;
            }

            .value {
                font-size: 16px;
                font-weight: 400;
                color: #161616;
            }
        }

    }

}

// desktop view
@media screen and (min-width: 768px) {
    .mobileAttendanceAdminDashBoard {
        display: none;
    }
}
@mixin inputField($width, $height, $fontSize) {
    width: $width !important;
    // height: $height;
    height: 40px;
    border: 1px solid #B7B7B7;
    // font-size: $fontSize;
    border-radius: 5px;

    @media screen and (max-width: 767px) {
        width: 100% !important;
        height: 40px;
        font-size: 18px;

    }

    &:focus,
    &:hover {
        outline: none;
        border: 1px solid #008080;
        // box-shadow: 0 0 10px #008080;
    }
}

@mixin textArea($width, $fontSize) {
    width: $width !important;
    // height: $height;
    border: 1px solid #B7B7B7;
    font-size: $fontSize;
    border-radius: 5px;

    @media screen and (max-width: 767px) {
        width: 100% !important;
        height: 40px;
        font-size: 18px;
    }

    &:focus,
    &:hover {
        outline: none;
        border: 1px solid #008080;
        // box-shadow: 0 0 10px #008080;
    }
}

@mixin dropDown($width, $height, $fontSize) {
    width: $width !important;
    // height: $height;
    height: 40px;
    // border: 1px solid #B7B7B7;
    font-size: $fontSize;
    border-radius: 7px;
    background: none;

    @media screen and (max-width: 767px) {
        width: 100% !important;
        height: 40px;
        font-size: 18px;
    }

    &:focus,
    &:hover {
        outline: none;
        // border: 1px solid #008080;
        // box-shadow: 0 0 10px #008080;
    }
}

// Mixin for multi-select drop-down
@mixin multiSelectDropDown($width, $height, $fontSize) {
    width: $width !important;
    // min-height: $height !important;
    min-height: 40px !important;
    // height: $height;
    max-height: auto;
    border: 1px solid #B7B7B7;
    font-size: $fontSize;
    border-radius: 7px;
    background: none;
    color: black;

    @media screen and (max-width: 767px) {
        width: 100% !important;
        height: 43px;
        font-size: 18px;
    }

    &:focus,
    &:hover {
        outline: none;
        border: 1px solid #008080;
        // box-shadow: 0 0 10px #008080;
    }

    // .ant-select-selector {
    //     border: none;
    //     background-color:transparent !important
    // }

    .ant-select-selection-item {
        color: black;
    }
}

@mixin button($width, $height, $fontSize) {
    // width: $width;
    width: auto;
    // height: $height;
    height: 40px;
    font-size: $fontSize;
    border-radius: 5px;
    background: #008080;
    color: white;
    border: none;
    cursor: pointer;

    @media screen and (max-width: 767px) {
        width: $width;
        height: $height;
        font-size: $fontSize;
    }

    &:focus,
    &:hover {
        outline: none;
        border: 1px solid #008080;
        // box-shadow: 0 0 10px #008080;
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

}

@mixin heading($fontSize) {
    font-size: $fontSize;
    font-weight: 400;

    @media screen and (max-width: 767px) {
        display: none;
    }

}

.ant-tabs-nav {
    margin: 0px 0px 0px 0px !important;
}
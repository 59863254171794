@import 'antd/dist/reset.css';

.flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flexSpaceBetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.w-100 {
    width: 100%;
}

.textCenter {
    text-align: center;
}

.heading {
    font-size: 32px;
    font-weight: 400;

    @media screen and (max-width: 767px) {
        display: none;
    }
}

.subHeading {
    font-size: 18px;
    font-weight: 400;
    color: #8A8A8A;

    @media screen and (max-width: 767px) {
        font-size: 16px;
        letter-spacing: 1px;
    }
}


.boxShadow {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
}


@media screen and (max-width: 767px) {
    .ant-form-item .ant-form-item-label label {
        font-size: 18px !important;
        color: #8A8A8A;
    }



    .ant-input {
        font-size: 16px;
    }



}

.ant-tabs-nav-wrap {
    padding-left: 20px;
}

// .ant-picker-dropdown .ant-picker-ranges .ant-picker-ok {
//     display: none;
// }

.ant-table-wrapper .ant-table-cell {
    padding: 10px 10px !important;
}

.ant-btn {
    &:hover {
        background-color: white !important;
        color: #008080 !important;
        border-color: #008080 !important;
        border: 1px solid #008080 !important
    }
}

.commonBtn {
    background: #008080;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    height: 40px;
}

.commonBtn:hover {
    background-color: white !important;
    color: #008080 !important;
    border-color: #008080 !important;
    border: 1px solid #008080 !important
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {

    @media screen and (max-width: 767px) {
        overflow: visible !important;
    }
}

// .approveButtonLoader{
//     color: white
// }
// .rejectButtonLoader{
//     color: burlywood
// }
.approveButtonLoader {
    color: #008080
}

.rejectButtonLoader {
    color: #ea5051
}

.approveButton {
    font-family: Poppins, sans-serif;
    border-radius: 5.508px;
    background-color: #008080;
    color: white;
    border: none;
    padding: 0px 20px;
    height: 40px;
    cursor: pointer;
}

.approveButton:hover {
    background-color: white;
    color: #008080;
    border: 1px solid #008080;
}

.rejectButton {
    border-radius: 5.508px;
    font-family: Poppins, sans-serif;
    background-color: #ea5051;
    color: white;
    border: none;
    padding: 0px 20px;
    height: 40px;
    cursor: pointer
}

.rejectButton:hover {
    background-color: white;
    color: #ea5051;
    border: 1px solid #ea5051
}

.linedivider {
    height: 1px;
    width: 100%;
    background-color: #f0f0f0;
}

// mobile
@media screen and (max-width: 767px) {
    .ant-picker-time-panel-column {
        touch-action: pan-y !important;
        overflow-y: scroll !important;
        -webkit-overflow-scrolling: touch !important;
    }

    .ant-picker-time-panel-cell {
        pointer-events: auto !important;
    }

    // .ant-picker-input>input {
    //     pointer-events: none !important;
    // }

    .ant-picker-input>input,
    .ant-picker input,
    .ant-picker .ant-picker-input input {
        pointer-events: none !important;
    }
}
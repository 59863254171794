$side-menu-header-background: #008080;

.sideMenuWithHeader {

    .header {
        text-align: center;
        color: #fff;
        height: 66px;
        padding-inline: 50px;
        line-height: 64px;
        background-color: #008080;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .globalSearch {
            position: relative;
            margin-left: 10%;
            width: 50%;

            input {
                width: 100%;
                height: 40px;
                border-radius: 26px;
                border: none;
                color: black;
                outline: none;
                padding-left: 60px;
            }

            .searchIcon {
                position: absolute;
                left: 20px;
                top: 50%;
                transform: translateY(-50%);
                color: #323232;
                font-size: 20px;
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                position: absolute;
                width: 100%;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                background-color: #fff;
                border: 1px solid #ccc;
                border-top: none;
                border-radius: 0 0 4px 4px;
                z-index: 1;
                margin-top: 5px;

                @media screen and (max-width: 767px) {
                    font-size: 12px;
                }

                li {
                    padding: 8px;
                    cursor: pointer;
                    color: black;

                    &:hover {
                        background-color: #f4f4f4;
                    }
                }
            }
        }

        .avatar-container {
            display: flex;
            align-items: center;
            text-align: center;
            position: absolute;
            top: 0px;
            right: 120px;

            .empSelected {
                position: relative;
                left: 0;
                border: 2px solid #3D2879;
                height: 50px;
                width: 50px;
                border-radius: 50%;
                background: white;
                color: black;
                font-size: 10px;
                font-weight: 600;
                z-index: 1;
            }

            .empNotSelected {
                position: relative;
                left: 40px;
                height: 40px;
                width: 40px;
                border-radius: 50%;
                background: white;
                color: black;
                font-size: 8px;
            }

            .adminSelected {
                position: relative;
                right: 40px;
                border: 2px solid #3D2879;
                height: 50px;
                width: 50px;
                border-radius: 50%;
                background: white;
                color: black;
                font-size: 10px;
                font-weight: 600;
                z-index: 1;
            }

            .adminNotSelected {
                position: relative;
                right: 10px;
                height: 40px;
                width: 40px;
                border-radius: 50%;
                background: white;
                color: black;
                font-size: 8px;
            }

            .adminNotSelectedDisable {
                position: relative;
                right: 10px;
                height: 40px;
                width: 40px;
                border-radius: 50%;
                background: #D9D9D9;
                color: black;
                font-size: 8px;
            }
        }

        .arrowContainer {
            width: 180px;
            background: white;
            box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
            z-index: 999;
            position: relative;
            top: 140px;
            border-radius: 4px;
            text-align: start;
        }

        .arrowContainer::before {
            content: '';
            position: absolute;
            top: -19px;
            right: 10px;
            margin-left: -10px;
            border-width: 10px 5px;
            border-style: solid;
            border-color: transparent transparent #fff transparent;
        }
    }

    .sideMenu {
        // height: 100vh;
        width: 90px !important;
        color: #fff;
        text-align: center;
        background-color: $side-menu-header-background !important;

        .logo {
            height: 60px;
            width: 90px;
            margin-top: 6px;
        }

        .sideMenuList {
            // margin-top: 3vh;

            ul {
                list-style: none;
                padding: 0;


                li {
                    // margin-bottom: 7px;
                    padding: 3px 0px 3px 0px;

                    &:hover {
                        background-color: #2ea4a4;
                    }

                    &.active {
                        background-color: white;
                    }

                    .menuItemContainer {
                        // padding: 8px 0px 1px 0px; 


                        .menuItemImage {
                            width: 16px;
                            height: 16px;
                            object-fit: contain;
                        }

                        .menuItemLabel {
                            font-size: 12px;
                            color: #fff;
                            font-weight: 400;
                            margin: 0px;
                            margin-top: 3px;
                            margin-bottom: 3px;
                        }

                        .activeMenuItemLabel {
                            font-size: 12px;
                            color: #008080;
                            font-weight: 400;
                            margin: 0px;
                            margin-top: 5px;
                            margin-bottom: 5px;
                        }
                    }

                }

            }
        }
    }


}
.reimbursementModelTitle {
    font-size: 20px;
    font-weight: 600;
}

.reimbursementModelKeyValueContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reimbursementModelKey {
    font-size: 18px;
    font-weight: 600;
    color: #272727;
}

.reimbursementModelValue {
    font-size: 16px;
    font-weight: 400;
    color: #161616;
}
.userDashBoard {
    // for desktop
    @media only screen and (min-width: 769px) {
      .dashboardContainer {
        // padding: 50px;
      }
  
      .section1 {
        display: flex;
        align-items: center;
        gap: 50px;
        background: white;
        padding: 20px;
  
        .section1Item {
          // width: 100px;
  
          p {
            font-size: 18px;
            font-weight: 400;
            color: #161616;
            margin-top: 10px;
          }
        }
      }
  
      .attendanceSection {
        background: white;
        padding: 13px 20px;
  
        .attendanceContainer {
          display: flex;
          justify-content: space-between;
          gap: 20px;
  
          .attendanceHeading {
            font-size: 24px;
            font-weight: 500;
            margin: 0px;
            margin-bottom: 10px;
            color: #161616;
          }
  
          .shiftContainer {
            display: flex;
            gap: 15px;
            margin-bottom: 10px;
  
            .shiftBtn {
              border: 1px solid #8a8a8a;
              width: 70px;
              height: 28px;
              border-radius: 25px;
              font-size: 14px;
              font-weight: 400;
              color: #8a8a8a;
              background: none;
              cursor: pointer;
            }
  
            .activeShiftBtn {
              width: 70px;
              height: 28px;
              background: #008080;
              color: white;
              border-radius: 25px;
              border: none;
            }
          }
  
          .attendanceDate {
            font-size: 18px;
            font-weight: 400;
            color: #161616;
          }
  
          .button {
            background: #008080;
            color: white;
            border-radius: 8px;
            // width: 100%;
            height: 40px;
            font-size: 18px;
            font-weight: 500;
            border: none;
            padding: 0px 16px;
          }
        }
  
        .shift {
          font-size: 18px;
          font-weight: 400;
          color: #8a8a8a;
        }
  
        .time {
          font-size: 16px;
          font-weight: 400;
          color: #8a8a8a;
          margin: 0px;
          margin-bottom: 10px;
        }
  
        .timeValue {
          font-size: 16px;
          font-weight: 500;
          color: #161616;
          margin: 0px;
          margin-bottom: 10px;
        }
  
        .seeMore {
          padding: 8px;
          background: #edecec;
          border-radius: 50%;
        }
  
        .hours {
          font-size: 20px;
          font-weight: 500;
          color: #161616;
        }
      }
  
      .inboxSection {
        background: white;
        padding: 20px;
  
        .inboxHeading {
          font-size: 24px;
          font-weight: 500;
          color: #161616;
        }
  
        .inboxMenuListContainer {
          display: flex;
          gap: 20px;
          margin-top: 20px;
  
          .inboxMenuBox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            padding: 7px 30px;
            border: 1px solid #8a8a8a;
            border-radius: 25px;
            cursor: pointer;
  
            .inboxMenuLabel {
              font-size: 16px;
              font-weight: 400;
              color: #8a8a8a;
              margin: 0;
            }
  
            .inboxMenuCount {
              font-size: 16px;
              font-weight: 400;
              color: #8a8a8a;
              margin: 0;
            }
          }
  
          .selectedInboxMenuBox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            padding: 7px 30px;
            border: 1px solid #008080;
            border-radius: 25px;
            background: #008080;
            cursor: pointer;
  
            .inboxMenuLabel {
              font-size: 16px;
              font-weight: 400;
              color: white;
              margin: 0;
            }
  
            .inboxMenuCount {
              font-size: 16px;
              font-weight: 400;
              color: #8a8a8a;
              margin: 0;
            }
          }
        }
  
        .inboxMessageContainer {
          // }
  
          .container {
            border-radius: 5px;
            // background-color: #f8f5f5;
            display: flex;
            // max-width: 882px;
            flex-direction: column;
            align-items: start;
            padding: 13px 10px;
          }
  
          .userContainer {
            align-self: stretch;
            display: flex;
            width: 100%;
            justify-content: space-between;
            gap: 20px;
          }
  
          .userBox {
            display: flex;
            justify-content: space-between;
            gap: 13px;
            white-space: nowrap;
          }
  
          .userImg {
            aspect-ratio: 1;
            object-fit: auto;
            object-position: center;
            width: 35px;
            height: 35px;
            border-radius: 35%;
          }
  
          .userDetailBox {
            display: flex;
            flex-grow: 1;
            flex-basis: 0%;
            flex-direction: column;
            margin: auto 0;
          }
  
          .userName {
            color: #1a1a1a;
            font-weight: 500;
            font-size: 16px;
            font-family: Poppins, sans-serif;
          }
  
          .dateWithDay {
            color: #2b2b2b;
            margin-top: 14px;
            font-family: Poppins, sans-serif;
          }
  
          .date {
            color: #2a2a2a;
            align-self: start;
            margin-top: 12px;
            font-family: Poppins, sans-serif;
          }
  
          .leaveType {
            color: #5d5d5d;
            white-space: nowrap;
            margin: 6px 0 0 55px;
            font-family: Poppins, sans-serif;
          }
  
          .actionsBox {
            display: flex;
            gap: 14px;
            font-size: 15px;
            font-weight: 400;
            white-space: nowrap;
            margin: 18px 0 0 55px;
          }
  
          .approveBtn {
            // leading-trim: both;
            // text-edge: cap;
            // font-family: pop;
            border-radius: 5.508px;
            background-color: #4ad471;
            flex-grow: 1;
            height: 40px;
            justify-content: center;
            color: var(--Color-Grayscale-0, #fff);
            padding: 0px 25px;
            border: none;
            cursor: pointer;
          }
  
          .rejectBtn {
            // leading-trim: both;
            // text-edge: cap;
            // font-family: Roboto, sans-serif;
            border-radius: 5.508px;
            border: 1.102px solid #d5d9dd;
            background-color: var(--Color-Grayscale-0, #fff);
            flex-grow: 1;
            height: 40px;
            justify-content: center;
            color: #616161;
            padding: 0px 25px;
            cursor: pointer;
          }
  
          hr {
            border: 1px solid #f0f0f0;
          }
        }
      }
  
      .attendanceSummay {
        // margin-top: 20px;
        background: white;
        // border-radius: 10px;
        padding: 20px 20px;
  
        .summaryHeading {
          font-size: 24px;
          font-weight: 500;
          color: #161616;
          margin: 0px;
        }
  
        .summaryBtnContainer {
          display: flex;
          gap: 20px;
          margin: 20px 0px;
  
          .selectedBtn {
            background: #008080;
            color: white;
            border-radius: 16px;
            width: 100px;
            height: 36px;
            font-size: 12px;
            font-weight: 400;
            border: none;
          }
  
          .unSelectedBtn {
            background: none;
            border: 1px solid #8a8a8a;
            color: #8a8a8a;
            border-radius: 16px;
            width: 100px;
            height: 36px;
            font-size: 12px;
            font-weight: 400;
            // border: none;
          }
        }
  
        .summaryContainer {
          padding: 25px 20px;
          border-radius: 4px;
          background: #fafafa;
        }
  
        .value {
          font-size: 24px;
          font-weight: 600;
          color: #145757;
          margin: 0;
        }
  
        .label {
          font-size: 18px;
          font-weight: 400;
          color: #161616;
          margin: 0;
          margin-top: 10px;
        }
      }
  
      .holidayContainer {
        // margin-top: 20px;
        background: white;
        // border-radius: 10px;
        padding: 10px 20px 3px 20px;
  
        .holidayHeading {
          font-size: 24px;
          font-weight: 500;
          color: #161616;
          margin: 0px;
        }
  
        .holidayBtnContainer {
          display: flex;
          gap: 20px;
  
          .holidayBtn {
            background: #008080;
            color: white;
            border-radius: 8px;
            width: 100px;
            height: 36px;
            font-size: 12px;
            font-weight: 400;
            border: none;
          }
  
          .holidayListContainer {
            margin-top: 20px;
  
            .holidayCard {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 20px;
              padding: 7px 20px;
              border-radius: 25px;
              cursor: pointer;
  
              .ocassionDate {
                font-size: 20px;
                font-weight: 500;
                margin: 0;
              }
            }
          }
        }
      }
  
      .myRequestContainer {
        // margin-top: 20px;
        background: white;
        // border-radius: 10px;
        padding: 20px 20px;
  
        .myRequestHeading {
          font-size: 24px;
          font-weight: 500;
          color: #161616;
        }
  
        .myRequestMenuContainer {
          display: flex;
          align-items: center;
          gap: 20px;
  
          .myRequestMenu {
            font-size: 12px;
            font-weight: 400;
            color: #8a8a8a;
            padding: 10px 20px;
            border: 1px solid #8a8a8a;
            border-radius: 16px;
          }
  
          .myRequestMenuActive {
            background: #008080;
            color: white;
            border-radius: 16px;
            padding: 10px 20px;
          }
        }
      }
  
      .celebrationsContainer {
        // border-radius: 10px;
        background: white;
        padding: 20px;
  
        .celebrationsHeading {
          font-size: 24px;
          font-weight: 500;
          color: #161616;
          margin: 0px;
        }
  
        .celebrationsListContainer {
          margin-top: 40px;
          display: flex;
          align-items: center;
  
          img {
            width: 60px;
            height: 60px;
            margin-right: 10px;
            /* Adjust margin for spacing between image and text */
            border-radius: 50%;
          }
  
          .celebrationText {
            color: #3354f4;
            font-size: 10px;
            font-weight: 400;
            background: #e3edff;
            border-radius: 12px;
            padding: 2px 10px;
            margin-top: 6px;
            margin-left: 0px;
            margin-right: 5px;
            text-align: center;
          }
  
          .employeeName {
            font-size: 16px;
            font-weight: 400;
            margin: 0;
            text-align: center;
          }
        }
      }
  
      .userImg {
        object-fit: fill;
        object-position: center;
        border-radius: 50%;
      }
    }
  
    // for mobile
    @media screen and (max-width: 768px) {
      .divider {
        height: 8px;
        background: #f7f8fc;
      }
  
      .section1 {
        display: flex;
        align-items: center;
        // gap: 40px;
        background: white;
        justify-content: space-between;
        padding: 16px 20px;
  
        .section1Item {
          // width: 100px;
  
          p {
            font-size: 16px;
            font-weight: 400;
            color: #161616;
            margin-top: 10px;
          }
        }
      }
  
      .inboxSection {
        background: white;
        padding: 16px;
  
        .inboxHeading {
          font-size: 24px;
          font-weight: 500;
          color: #161616;
        }
  
        /* Hide scrollbar for Chrome, Safari and Opera */
        .inboxMenuListContainer::-webkit-scrollbar {
          display: none;
        }
  
        /* Hide scrollbar for IE, Edge and Firefox */
        .inboxMenuListContainer {
          -ms-overflow-style: none;
          /* IE and Edge */
          scrollbar-width: none;
          /* Firefox */
        }
  
        .inboxMenuListContainer {
          display: -webkit-inline-box;
          gap: 20px;
          width: 90vw;
          overflow: scroll;
  
          .inboxMenuBox {
            display: flex;
            // justify-content: space-between;
            align-items: center;
            gap: 10px;
            padding: 7px 15px;
            border: 1px solid #8a8a8a;
            border-radius: 25px;
            margin-bottom: 20px;
            cursor: pointer;
  
            .inboxMenuLabel {
              font-size: 12px;
              font-weight: 400;
              color: #8a8a8a;
              margin: 0;
              // padding: 0px 15px;
            }
  
            .inboxMenuCount {
              font-size: 12px;
              font-weight: 400;
              color: #8a8a8a;
              margin: 0;
              // padding: 0px 15px;
            }
          }
  
          .selectedInboxMenuBox {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 7px 15px;
            border: 1px solid #008080;
            border-radius: 25px;
            margin-bottom: 20px;
            background: #008080;
            cursor: pointer;
  
            .inboxMenuLabel {
              font-size: 12px;
              font-weight: 400;
              color: white;
              margin: 0;
            }
  
            .inboxMenuCount {
              font-size: 12px;
              font-weight: 400;
              color: #8a8a8a;
              margin: 0;
            }
          }
        }
  
        .inboxMessageContainer {
          // }
          .container {
            padding: 10px 0px;
  
            .userContainer {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 4px 0px;
  
              .userName {
                color: #161616;
                font-weight: 500;
                font-size: 18px;
                font-family: Poppins, sans-serif;
                margin: 0px;
              }
  
              .date {
                color: #333333;
                font-size: 16px;
                margin-top: 12px;
                margin: 0px;
                font-family: Poppins, sans-serif;
              }
            }
  
            .dateWithDay {
              color: #333333;
              margin-top: 14px;
              font-size: 16px;
              font-family: Poppins, sans-serif;
              margin: 0px;
            }
  
            .leaveType {
              color: #333333;
              font-size: 16px;
              font-family: Poppins, sans-serif;
              margin-top: 8px;
            }
  
            .actionsBox {
              display: flex;
              gap: 14px;
              font-size: 16px;
              font-weight: 400;
  
              .approveBtn {
                border-radius: 5px;
                background-color: #4ad471;
                // flex-grow: 1;
                justify-content: center;
                color: white;
                padding: 0px 10px;
                height: 40px;
                border: none;
              }
  
              .rejectBtn {
                border-radius: 5px;
                border: 1.102px solid #d5d9dd;
                background-color: white;
                // flex-grow: 1;
                justify-content: center;
                color: #616161;
                padding: 0px 10px;
                height: 40px;
              }
            }
          }
  
          hr {
            border: 1px solid #f0f0f0;
          }
        }
      }
  
      .attendanceSection {
        background: white;
        padding: 16px;
  
        .attendanceHeading {
          font-size: 24px;
          font-weight: 500;
          color: #161616;
          margin: 0px;
          margin-bottom: 15px;
        }
  
        .shiftContainer {
          display: flex;
          gap: 15px;
          margin-bottom: 15px;
  
          .shiftBtn {
            border: 1px solid #8a8a8a;
            width: 70px;
            height: 28px;
            border-radius: 25px;
            font-size: 12px;
            font-weight: 400;
            color: #8a8a8a;
            background: none;
            cursor: pointer;
          }
  
          .activeShiftBtn {
            width: 70px;
            height: 28px;
            background: #008080;
            color: white;
            border-radius: 25px;
            border: none;
            font-size: 12px;
          }
        }
  
        .attendanceDate {
          font-size: 16px;
          font-weight: 400;
          color: #161616;
        }
  
        .button {
          background: #008080;
          color: white;
          border-radius: 8px;
          width: 90vw;
          height: 40px;
          font-size: 16px;
          font-weight: 500;
          border: none;
        }
  
        .shift {
          font-size: 16px;
          font-weight: 400;
          color: #8a8a8a;
        }
  
        .time {
          font-size: 16px;
          font-weight: 500;
          color: #8a8a8a;
        }
  
        .timeValue {
          font-size: 16px;
          font-weight: 500;
          color: #161616;
        }
  
        .seeMore {
          padding: 8px;
          background: #edecec;
          border-radius: 50%;
        }
  
        .hours {
          font-size: 16px;
          font-weight: 500;
          color: #161616;
        }
      }
  
      .attendanceSummay {
        margin-top: 20px;
        background: white;
        padding: 16px;
  
        .summaryHeading {
          font-size: 24px;
          font-weight: 500;
          color: #161616;
          margin: 0px;
        }
  
        .summaryBtnContainer {
          display: flex;
          gap: 20px;
          margin: 20px 0px;
  
          .selectedBtn {
            background: #008080;
            color: white;
            border-radius: 8px;
            width: 100px;
            height: 32px;
            font-size: 12px;
            font-weight: 400;
            border: none;
          }
  
          .unSelectedBtn {
            background: #edecec;
            color: #8a8a8a;
            border-radius: 8px;
            width: 100px;
            height: 32px;
            font-size: 12px;
            font-weight: 400;
            border: none;
          }
        }
  
        .summaryContainer {
          padding: 7px 20px;
          border-radius: 5px;
          background: #fafafa;
        }
  
        img {
          width: 30px;
        }
  
        .value {
          font-size: 16px;
          font-weight: 600;
          color: #145757;
          margin: 0;
        }
  
        .label {
          font-size: 12px;
          font-weight: 400;
          color: #161616;
          margin: 0;
          margin-top: 10px;
        }
      }
  
      .myRequestContainer {
        margin-top: 20px;
        background: white;
        // border-radius: 10px;
        padding: 0px 16px;
  
        .myRequestHeading {
          font-size: 24px;
          font-weight: 500;
          color: #161616;
        }
  
        /* Hide scrollbar for Chrome, Safari and Opera */
        .myRequestMenuContainer::-webkit-scrollbar {
          display: none;
        }
  
        /* Hide scrollbar for IE, Edge and Firefox */
        .myRequestMenuContainer {
          -ms-overflow-style: none;
          /* IE and Edge */
          scrollbar-width: none;
          /* Firefox */
        }
  
        .myRequestMenuContainer {
          display: flex;
          align-items: center;
          gap: 20px;
          overflow: auto;
  
          .myRequestMenu {
            font-size: 12px;
            font-weight: 400;
            color: #8a8a8a;
            padding: 10px 20px;
            border: 1px solid #8a8a8a;
            border-radius: 16px;
            margin-bottom: 20px;
          }
  
          .myRequestMenuActive {
            font-size: 12px;
            background: #008080;
            color: white;
            border-radius: 16px;
            padding: 10px 20px;
            margin-bottom: 20px;
          }
        }
      }
  
      .holidayContainer {
        margin-top: 20px;
        background: white;
        // border-radius: 10px;
        padding: 16px;
  
        .holidayHeading {
          font-size: 24px;
          font-weight: 500;
          color: #161616;
          margin: 0px;
        }
  
        .holidayBtnContainer {
          display: flex;
          gap: 20px;
  
          .holidayBtn {
            background: #008080;
            color: white;
            border-radius: 8px;
            width: 100px;
            height: 36px;
            font-size: 12px;
            font-weight: 400;
            border: none;
          }
  
          .holidayListContainer {
            margin-top: 20px;
  
            .holidayCard {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 20px;
              padding: 7px 20px;
              border-radius: 25px;
              cursor: pointer;
  
              .ocassionDate {
                font-size: 20px;
                font-weight: 500;
                margin: 0;
              }
            }
          }
        }
      }
  
      .compensationContainer {
        background: white;
        padding: 16px;
  
        .compensationHeading {
          font-size: 24px;
          font-weight: 500;
          color: #161616;
          margin: 0px;
        }
  
        .compensationValue {
          font-size: 16px;
          font-weight: 600;
          color: #145757;
          margin: 0;
        }
      }
  
      .celebrationsContainer {
        // border-radius: 10px;
        background: white;
        padding: 16px;
  
        .celebrationsHeading {
          font-size: 24px;
          font-weight: 500;
          color: #161616;
          margin: 0px;
        }
  
        .celebrationsListContainer {
          margin-top: 20px;
          display: flex;
          align-items: center;
          gap: 20px;
  
          img {
            width: 47px;
            height: 47px;
            margin-right: 10px;
            /* Adjust margin for spacing between image and text */
            border-radius: 50%;
          }
  
          .celebrationText {
            color: #3354f4;
            font-size: 10px;
            font-weight: 400;
            background: #e3edff;
            border-radius: 12px;
            padding: 2px 10px;
            margin-top: 6px;
            margin-left: 0px;
            margin-right: 5px;
            text-align: center;
          }
  
          .employeeName {
            font-size: 16px;
            font-weight: 400;
            margin: 0;
            text-align: center;
          }
        }
      }
    }
  }
  
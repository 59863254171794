.mobileMenu {

    .content {
        overflow-x: hidden;
        width: 100%;
        margin-bottom: 10vh;

        .header {
            // padding: 0px 15px;
            background: #008080;
            color: #fff;
            height: 63px;
            border-radius: 0px 0px 10px 10px;
            position: fixed;
            width: 100%;
            z-index: 9;
            overflow: hidden;
            top: 0;

            .menuTitle {
                font-size: 18px;
                margin-top: 16px;
            }

            .avatar-container {
                display: flex;
                align-items: center;
                text-align: center;
                position: absolute;
                right: 70px;

                .empSelected {
                    position: relative;
                    left: 2px;
                    border: 2px solid #3D2879;
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    background: white;
                    color: black;
                    font-size: 8px;
                    font-weight: 400;
                    z-index: 1;
                }

                .empNotSelected {
                    position: relative;
                    left: 28px;
                    height: 25px;
                    width: 25px;
                    border-radius: 50%;
                    background: white;
                    color: black;
                    font-size: 8px;
                }

                .adminSelected {
                    position: relative;
                    right: 25px;
                    border: 2px solid #3D2879;
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    background: white;
                    color: black;
                    font-size: 8px;
                    font-weight: 400;
                    z-index: 1;
                }

                .adminNotSelected {
                    position: relative;
                    right: 0px;
                    height: 25px;
                    width: 25px;
                    border-radius: 50%;
                    background: white;
                    color: black;
                    font-size: 8px;
                }
            }

            .arrowContainer {
                width: 210px;
                background: white;
                box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
                z-index: 999;
                position: fixed;
                top: 56px;
                right: 10px;
                border-radius: 4px;
                text-align: start;
            }

            .arrowContainer::before {
                content: '';
                position: absolute;
                top: -19px;
                right: 10px;
                margin-left: -10px;
                border-width: 10px 5px;
                border-style: solid;
                border-color: transparent transparent #fff transparent;
            }
        }

        .main-content {
            z-index: 999;
            margin-top: 64px;
            height: 88vh;
            overflow-x: hidden;
        }

        .footer {
            position: fixed;
            bottom: 0 !important;
            width: 100%;
            text-align: center;
            z-index: 999;

            ul {
                list-style-type: none;
                margin: 0;
                padding-top: 15px;
                padding-left: 0;
                display: flex;
                justify-content: space-evenly;
                align-items: baseline;
                background-color: #F3F3F3;

                li {
                    display: inline-block;
                    text-decoration: none;
                    font-size: 11px;
                    color: #717070;

                    .activeMenuLabel {
                        color: #008080;
                        font-size: 12px;
                        margin-top: 10px;
                    }

                    .menuLabel {
                        color: #5C5E61;
                        font-size: 12px;
                        margin-top: 10px;
                    }
                }
            }
        }

        .footer.hidden {
            display: none;
        }
    }
}


.backgroundContainer {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    z-index: 998;
    padding-bottom: 20px;

    .morePopUpContainer {
        background-color: white;
        justify-content: end;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        margin-bottom: 76px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;

        // .close {
        //     display: flex;
        //     justify-content: end; 

        // }

        .more {
            // display: flex;
            justify-content: center;
            padding-bottom: 15px;

            .grid-container {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-gap: 16px;
                max-width: 800px;
                margin: 0 auto;
            }
        }
    }
}
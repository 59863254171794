.ApprovedLeaveButton {
    width: 107px;
    height: 40px;
    background: #28b15f;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.WithdrawLeaveButton {
    width: 107px;
    height: 45px;
    background: #f9a1b5;
    color: white;
    border: none;
    border-radius: 4px;
}

.RejectedLeaveButton {
    width: 107px;
    height: 40px;
    background: #f53444;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.PendingLeaveButton {
    width: 107px;
    height: 32px;
    background: #f6f6f6;
    color: #bfbebe;
    border: none;
    border-radius: 4px;
}
@import "../../../styles/_mixins";



// employeeLeaveColumn
.SignedButton {
  // width: 121px;
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 30px;
  background: #DBFFE9;
  color: #008080;
  font-size: 14px;
  line-height: 24px;
  border: none;
  border-radius: 24px;
  padding: 6px 15px 6px 15px;

  @media screen and (max-width: 768px) {
    width: 83px;
    // height: 22px;
    font-size: 10px;
  }
}

.Not-SignedButton {
  width: auto !important;
  height: auto !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFDBDB;
  color: #FD3434;
  font-size: 14px;
  line-height: 24px;
  border: none;
  border-radius: 24px;
  padding: 6px 15px 6px 15px;
  @media screen and (max-width: 768px) {
    width: 73px;
    height: 22px;
    font-size: 10px;
  }
}

.customScrollbar {
  &::-webkit-scrollbar {
      width: 12px;
  }

  &::-webkit-scrollbar-track {
      // background: #f1f1f1;
      background: none;
      border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
      // background: #bebbbb;
      background: none;
      border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
      background: #555;
  }
}
